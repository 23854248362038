@import '~@travel-ui/styles/src/utility';
@import '../../core/App/app.module.scss';

// HELP: Add here you customs styles for this component
.appBannerAndEmailSubsWrapper {
  display: flex;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  margin-top: -100px;

  @include media(sp) {
    margin-top: -80px;
  }

  @include media(pc) {
    @include pc-layout;
  }
}

.rentalCarBanner {
  @include media(sp) {
    margin: 0 $spacing-16 $spacing-16;
  }
}

.topLabel {
  @include media(sp) {
    @include font-sp-header-h5-bold;
  }

  @include media(tl, pc) {
    @include font-pc-header-h3-bold;
  }
}
