@import '~@travel-ui/styles/src/utility';

.wrapper {
  @include media(sp, tl) {
    padding: $spacing-16;
  }

  @include media(pc) {
    padding-bottom: $spacing-16;
  }
}

.loginPrompt {
  display: flex;
  align-items: center;
  align-self: stretch;
  padding: $spacing-16;
  padding-left: $spacing-32;
  gap: $spacing-16;
  border-radius: 12px;
  background-color: $shady-white;

  @include media(sp, tl) {
    flex-direction: column;
    padding: $spacing-24;
    padding-top: $spacing-16;
    gap: $spacing-08;
    .loginPromptContent {
      text-align: center;
    }
  }
}

.loginPromptIcon {
  display: flex;
  height: 48px;
  width: 48px;
  margin: auto 0;
  @include media(sp, tl) {
    height: 36px;
    width: 36px;
  }
}

.loginPromptContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  flex: 1 0 0;

  .header {
    width: 100%;
    @include font-pc-header-h4-bold;
    @include media(sp, tl) {
      @include font-sp-header-h5-bold;
    }
    color: $lincoln-green;
  }

  .body {
    width: 100%;
    @include font-pc-body-b2;
    @include media(sp, tl) {
      @include font-sp-header-h6;
    }
  }
}

.loginPromptButton {
  border-radius: 8px;
  @include media(sp, tl) {
    align-self: stretch;
    margin-top: 8px;
    max-width: 100%;
  }
}
