@import '~@travel-ui/styles/src/utility';

.valuePropositionContainer {
  position: relative;
  padding-bottom: $spacing-16;
  z-index: 0;

  @include media(tl) {
    margin-top: $spacing-24;
  }

  @include media(sp) {
    margin-top: $spacing-16;
    padding-bottom: $spacing-08;
  }

  .loaderImage {
    height: 80px;
  }

  .swiperContent {
    width: 100%;

    .slideHeader {
      font-size: $font-uranus;
      font-weight: 700;
      line-height: 24px;
      text-align: center;
      margin-bottom: $spacing-24;
    }

    .generalContentWrapper {
      display: flex;
      justify-content: center;
      overflow: hidden;

      .item {
        display: flex;
        padding: 0 $spacing-12 $spacing-20;

        .imageStyle {
          margin-right: $spacing-12;
          .media {
            height: 48px;
            width: auto;
            background: transparent;
          }
        }

        .textStyle {
          width: 172px;
          margin-bottom: $spacing-20;

          .title {
            @include font-label;
            margin-bottom: $spacing-04;
          }

          .descriptions {
            font-size: 13px;
            line-height: 18px;
          }
        }
      }
    }

    .bannerContentWrapper {
      display: flex;
      justify-content: center;
      overflow: hidden;

      .item {
        display: flex;
        padding: 0 $spacing-12 $spacing-20;

        .imageStyle {
          margin-right: $spacing-16;
          .media {
            height: 32px;
            width: auto;
            background: transparent;
          }
        }

        .textStyle {
          width: 314px;
          margin-bottom: $spacing-20;

          .descriptions {
            font-size: 13px;
            line-height: 18px;
          }
        }
      }
    }
  }

  .spContent {
    display: flex;
    position: relative;

    .mainTitle {
      position: absolute;
      width: 100%;
      text-align: center;
      font-size: $font-neptune;
      line-height: $line-height-title-sp;
      font-weight: 700;
    }

    .scrollableContent {
      margin-top: $spacing-36;
      .scrollableItem {
        .item {
          padding: 0 0 $spacing-16 $spacing-16;

          .itemContent {
            display: flex;
          }

          .imageStyle {
            margin-right: $spacing-08;
            .media {
              height: 43px;
              width: auto;
              background: transparent;
            }
          }

          .textStyle {
            margin-bottom: 0;
            width: 182px;

            .title {
              @include font-label;
              margin-bottom: $spacing-04;
            }

            .descriptions {
              font-size: 13px;
              line-height: 18px;
            }
          }
        }

        .generalContentWrapperSP {
          .media {
            height: 43px;
          }
        }

        .bannerContentWrapperSP {
          &.item {
            .imageStyle {
              margin-top: $spacing-04;
              .media {
                height: 20px;
              }
            }

            .textStyle {
              width: 242px;
            }
          }
        }

        &:last-child {
          margin-right: $spacing-16;
        }
      }
    }
  }
}
