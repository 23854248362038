@import '~@travel-ui/styles/src/utility';

.rentalCarBanner {
  display: flex;
  align-items: center;
  border: 1px solid $pastel-gray;
  justify-content: space-between;
  overflow: hidden;
  text-decoration: none;
  color: $jet;

  @include media(pc) {
    border-radius: $border-radius-8px;
    gap: $spacing-24;
    padding-right: $spacing-20;
    margin-bottom: $spacing-32;
  }

  @include media(tl) {
    margin: 0 $spacing-16;
  }

  @include media(sp, tl) {
    border-radius: $border-radius-4px;
    gap: $spacing-08;
    padding-right: $spacing-04;
    margin-bottom: $spacing-16;
  }
}
.rentalCarImg {
  display: flex;
  overflow: hidden;
  position: relative;
  align-self: stretch;
  @include media(pc) {
    width: 340px;
    align-items: flex-end;
  }

  @include media(sp, tl) {
    min-width: 100px;
    align-items: flex-end;
  }
}

.rentalCarImg img {
  object-fit: cover;
  position: absolute;
  @include media(pc) {
    bottom: -$spacing-12;
  }
  @include media(sp, tl) {
    bottom: -$spacing-04;
  }
}

.rentalCarContent {
  margin-right: auto;

  @include media(pc) {
    padding: $spacing-12 0;
    .title {
      @include font-pc-header-h3-bold;
    }

    .subtitle {
      @include font-pc-header-h6;
    }
  }

  @include media(sp, tl) {
    padding: $spacing-08 0;
    .title {
      @include font-sp-header-h6-bold;
    }

    .subtitle {
      @include font-sp-captions-c1;
    }
  }
}

.exploreRentalButton {
  @include font-pc-header-h6;
  height: 48px;
  min-width: 155px;
  white-space: nowrap;
  max-width: fit-content;
  border-radius: $border-radius-8px;
  padding: 0 $spacing-16;
}
