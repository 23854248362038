@import '~@travel-ui/styles/src/base';
// Removing <body> tag margin/padding css
@import '~@travel-ui/styles/src/base';

$page-side-padding--pc: $spacing-16;
$max-content-width--pc: 1128px;

@mixin pc-layout {
  margin-left: auto;
  margin-right: auto;
  padding-left: $page-side-padding--pc;
  padding-right: $page-side-padding--pc;
  max-width: $max-content-width--pc + ($page-side-padding--pc * 2);
  width: 100%;
}

@mixin fullscreen-box {
  left: 0;
  width: 100vw;
  position: absolute;
  z-index: -1;
}

body {
  margin: 0;
  padding: 0;
  -webkit-text-size-adjust: 100%;
}

header {
  @include media(pc, tl) {
    height: $traveler-header-height-pc;
  }
}

.errorPageSubText p:not(:last-child) {
  margin-bottom: $spacing-16;
}

.appNavigationButton {
  @include resetButtonStyle;

  color: $cilantro;
  cursor: pointer;
}
