@import '~@travel-ui/styles/src/utility';
@import '../../../../core/App/app.module.scss';

.wrapper {
  border: 1px solid $isabelline;
  background-color: $white;
  z-index: $zIndex-overlay1;
  
  @include media(sp) {    
    @include border-radius-all($border-radius-12px);
    padding: $spacing-16;
    box-shadow: 0px 0px 8px 0px #00000014; 
  }
  
  @include media(pc) {
    width: 100%;
    max-width: $max-content-width--pc;
    align-self: center;
    margin-bottom: $spacing-32;
  }
  
  @include media(sp, tl) {
    margin: 0 $spacing-16 0;
  }
  
  @include media(tl, pc) {
    @include border-radius-all($border-radius-16px);
    min-height: 152px;
    padding: $spacing-24 $spacing-24 $spacing-04;
    box-shadow: 0px 4px 16px 0px #00000014;
  }
}

.titleWrapper {
  text-align: center;
}

.topLabel {
  @include media(sp) {
    @include font-sp-header-h5-bold;
  }
  
  @include media(tl, pc) {
    @include font-pc-header-h3-bold;
  }
}